import React from 'react';
import { createRoot } from "react-dom/client";

function make_id (length) {
    let result             = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
}

class TodoList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            todoItems: []
        };
    }

    async dataLoad () {
        const response = await fetch("/", {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            // body: JSON.stringify({key: 'test-1', data: {title: 'test One', description: "The first description"}}) // body data type must match "Content-Type" header
        });
        return response.json();
    }

    async componentDidMount () {
        const renderItems = await this.dataLoad();

        this.setState(prevState => {
            return { todoItems: renderItems }
        })
    }

    onClickAdd () {
        const key = make_id(6)
        let todoItem = {
            key: key,
            listId: key,
            title: "Hello there",
            description: "This is the description",
            hearts: 0
        };
        this.setState(prevState => {
            return { todoItems: [ ...prevState.todoItems, todoItem ]}
        },
            async () => {
                const response = await fetch("/", {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(todoItem) // body data type must match "Content-Type" header
                });
            }
        );
    }

    onChildUpdate (key, state) {
        this.setState(prevState => {
            return {
                todoItems: prevState.todoItems.map(
                    item => item.key == key ? {...item, ...state} : item
                )
            }
        },
            async () => {
                const response = await fetch("/" + key, {
                    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify( state ) // body data type must match "Content-Type" header
                });
            }
        );
    }

    onChildDelete (key) {
        this.setState(prevState => {
            return {
                todoItems: prevState.todoItems.filter((item) => item.key != key)
            }
        },
            async () => {
                const response = await fetch("/" + key, {
                    method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                });
            }
        );
    }

    onClickNuke () {
        this.setState(prevState => {
            return { todoItems: []}
        },
            async () => {
                const response = await fetch("/", {
                    method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                });
            }
        );
    }

    render() {
        return (
            <section className="section">
                <h1 className="title">Todo List</h1>
                <h2 className="subtitle">A simple app for tracking a simple todo list.</h2>

                <nav className="level">
                    <div className="level-left">
                        <p className="level-item">
                            <button className="button is-primary" onClick={() => this.onClickAdd()}>+ Create</button>
                        </p>
                        <p className="level-item">
                            {this.state.todoItems.length > 0 &&
                                <button className="button is-danger" onClick={() => this.onClickNuke()}>Nuke</button>
                            }
                        </p>
                    </div>

                </nav>

                <div className="container">
                    {this.state.todoItems.map(item => (
                        <TodoItem
                            key={item.key}
                            listId={item.key}
                            title={item.title}
                            hearts={item.hearts}
                            description={item.description}
                            onDelete={() => this.onChildDelete(item.key)}
                            onUpdate={(state) => this.onChildUpdate(item.key, state)}
                        />
                    ))}
                </div>
            </section>
        );
    }
}

class TodoItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listId: this.props.listId,
            title: this.props.title,
            description: this.props.description,
            hearts: this.props.hearts
        }
    }

    heart () {
        this.setState({hearts: this.state.hearts+1}, () => {
            this.props.onUpdate(this.state)
        });

    }

    render() {
        return (
            <article className="media">
                <figure className="media-left">
                    <p className="image is-64x64">
                        <img src="https://bulma.io/images/placeholders/128x128.png" />
                    </p>
                </figure>
                <div className="media-content">
                    <div className="content">
                        <p>
                            <strong>{this.state.title}</strong> <small>{this.state.listId}</small> <small>31m</small>
                            <br />
                            {this.state.description}
                        </p>
                    </div>
                    <nav className="level is-mobile">
                        <div className="level-left">
                            <button className="button is-link is-small is-outlined" onClick={() => this.heart()}>
                                {this.state.hearts} hearts
                            </button>

                        </div>
                    </nav>
                </div>
                <div className="media-right">
                    <button className="delete" onClick={() => this.props.onDelete()}></button>
                </div>
            </article>
        );
    }
}

const root = createRoot( document.getElementById("todo") );
root.render(<TodoList />);